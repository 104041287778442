import {
  Alert,
  Container,
  Header,
  Link,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import { Image } from "react-bootstrap";
import { BasePageProps } from "../../../common/pageProps";
import { CodeBlockWithCopy } from "../../../common/utils";
import { Images } from "../../../images";

const codeText =
  'Set-ExecutionPolicy -ExecutionPolicy Unrestricted -Scope Process -Force\n& "C:\\Program Files (x86)\\Amazon\\SiteConnect\\configure-siteconnect.ps1" install';

const containerDescription = (
  <SpaceBetween direction="vertical" size="xxs">
    SiteConnect requires the installation of two pieces of software:
    <ul>
      <li>
        <Link href="#install-aws-vpn-client">AWS VPN Client</Link>
      </li>
      <li>
        <Link href="#install-siteconnect-posture-client">
          SiteConnect Posture Assessment Client
        </Link>
      </li>
    </ul>
    <Alert type="warning">
      You must have administrative access to the machine to complete the
      installation.
    </Alert>
  </SpaceBetween>
);

export const InstallPage = ({ setPageTitle }: BasePageProps) => {
  setPageTitle("Windows Install");
  return (
    <Container
      fitHeight
      header={<Header variant="h1" description={containerDescription} />}
    >
      <h2 id="install-aws-vpn-client">AWS VPN Client</h2>
      <ol>
        <li>
          Download the{" "}
          <Link
            external
            href="https://d20adtppz83p9s.cloudfront.net/WPF/latest/AWS_VPN_Client.msi"
          >
            AWS VPN Client
          </Link>
        </li>
        <li>
          <p>Install the MSI file</p>
          <Image src={Images.installVpnClient} alt="Install AWS VPN Client" />
        </li>
        <ul>
          <li>
            <p>
              Accept the license agreement, and click <code>Install</code>
            </p>
            <Image
              src={Images.installVpnClientLicenseAgreement}
              alt="Agree To License"
            />
          </li>

          <li>
            <p>
              Click <code>Finish</code>
            </p>
            <Image
              src={Images.installVpnClientFinish}
              alt="AWS VPN Client Install Finished"
            />
          </li>
        </ul>
      </ol>

      <h2 id="install-siteconnect-posture-client">
        SiteConnect Posture Assessment Client
      </h2>
      <ol>
        <li>
          Download the{" "}
          <Link href="https://downloads.siteconnect.opstech.a2z.com/latest/WINDOWS/siteconnect-web-server.msi">
            Posture Assessment Client
          </Link>
        </li>
        <li>
          <p>Install the MSI file</p>
          <Image
            src={Images.installSCPostureClient}
            alt="Install SiteConnect Posture Assessment Client"
          />
        </li>
        <ul>
          <li>
            <p>
              Click <code>Next</code>
            </p>
            <Image
              src={Images.installSCPostureClientDestDir}
              alt="Install Destination Directory"
            />
          </li>
          <li>
            <p>
              Click <code>Finish</code>
            </p>
            <Image
              src={Images.installSCPostureClientFinish}
              alt="SiteConnect Install Finished"
            />
          </li>
        </ul>
        <li>
          Run PowerShell as an administrator
          <p>
            <strong>NOTE:</strong> Do not use the ISE or (x86) version of
            powershell. Use the Windows Powershell that looks like the option at
            the top of the screenshot below.
          </p>
          <Image
            src={Images.runPSAdmin}
            alt="Run PowerShell As Administrator"
          />
        </li>
        <li>
          <p>
            Copy &amp; Paste the following command in PowerShell to execute the
            configuration script:
          </p>
          <CodeBlockWithCopy code={codeText} />
          <p>
            If you see the text{" "}
            <code>SiteConnect has been successfully installed</code>, then the
            installation is complete.
          </p>
          <p>
            You can test this by opening this link{" "}
            <Link
              external
              href="http://127.0.0.1:32182"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://127.0.0.1:32182
            </Link>{" "}
            and view the posture payload that is returned to SiteConnect. If you
            see any output, SiteConnect is installed correctly:
          </p>
          <Image
            src={Images.scTestSuccess}
            alt="SiteConnect Successfully Installed"
            style={{ border: "1px solid black" }}
            width={1000}
          />
          <p>
            If the browser refuses to connect, then the Posture Assessment
            Client is <strong>not</strong> installed correctly:
          </p>
          <Image
            src={Images.scTestFail}
            alt="SiteConnect Unsuccessfully Installed"
            style={{ border: "1px solid black" }}
            width={1000}
          />
        </li>
      </ol>

      <h2>Next Steps</h2>
      <p>
        You will now be able to successfully{" "}
        <Link href="/windows/vpn-connect.html">connect to the VPN</Link>.
      </p>
    </Container>
  );
};
