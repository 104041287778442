import {
  Alert,
  Button,
  Container,
  ExpandableSection,
  FlashbarProps,
  Input,
  Link,
  SpaceBetween,
  Spinner,
  Table,
} from "@amzn/awsui-components-react";
import {
  returnCurrentPosturePayload,
  returnPosturePayload,
} from "@amzn/siteconnect-idp-posture-assessment/dist/lib/network";
import { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { ACCOUNT_MAPPINGS } from "../../../common/constants";
import { PostureResult } from "../../../common/models";
import { BasePageProps } from "../../../common/pageProps";
import { CodeBlockWithCopy, getPostureResult } from "../../../common/utils";
import "../../../css/custom.css";
import { Images } from "../../../images";

interface PostureTypeResult {
  reason?: string;
  result?: string;
}

interface TroubleshootingPageProps extends BasePageProps {
  createFlashbarMessage: (content: string, type: FlashbarProps.Type) => void;
}

const chooseResult = (
  postureResult: string | undefined
): string | undefined => {
  if (postureResult === undefined) {
    return "success";
  } else if (postureResult === "undetermined") {
    return undefined;
  }
  return "fail";
};

export const TroubleshootingPage = ({
  createFlashbarMessage,
  setPageTitle,
}: TroubleshootingPageProps) => {
  setPageTitle("Windows Troubleshooting");
  const codeText =
    'Set-ExecutionPolicy -ExecutionPolicy Unrestricted -Scope Process -Force\n& "C:\\Program Files (x86)\\Amazon\\SiteConnect\\configure-siteconnect.ps1" install';

  const powershellLogCode = `Get-ChildItem "$($env:USERPROFILE)\\AppData\\Roaming\\AWSVPNClient\\logs" | Sort-Object -Property CreationTime | Where-Object { $_.FullName -like "*ovpn_aws_vpn_client*"} | Select-Object -Last 1 FullName`;

  const [postureResult, setPostureResult] = useState<PostureResult>();
  const [loading, setLoading] = useState<boolean>(true);
  const [assessingPosture, setAssessingPosture] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>();

  const [postureHeader, setPostureHeader] = useState<PostureTypeResult>({
    reason: undefined,
    result: undefined,
  });
  const [supportedOS, setSupportedOS] = useState<PostureTypeResult>({
    reason: undefined,
    result: undefined,
  });
  const [OSType, setOSType] = useState<PostureTypeResult>({
    reason: undefined,
    result: undefined,
  });
  const [firewall, setFirewall] = useState<PostureTypeResult>({
    reason: undefined,
    result: undefined,
  });
  const [antivirus, setAntivirus] = useState<PostureTypeResult>({
    reason: undefined,
    result: undefined,
  });
  const [structure, setStructure] = useState<PostureTypeResult>({
    reason: undefined,
    result: undefined,
  });

  useEffect(() => {
    const getPayload = async () => {
      setLoading(true);
      const payload = JSON.stringify(await returnPosturePayload());
      getPostureResult(payload, userId)
        .then((r) => {
          setPostureResult(r.data);
        })
        .catch((err) => {
          createFlashbarMessage(
            `There was an issue assessing posture: ${
              err.message
            }. Unable to complete request to https://${ACCOUNT_MAPPINGS.get(
              window?.location.hostname
            )}`,
            "error"
          );
          setPostureResult({
            result: false,
            reason: {
              "os.type": "undetermined",
              "os.version": "undetermined",
              antivirus: "undetermined",
              firewall: "undetermined",
              structure: "undetermined",
            },
          });
        })
        .finally(() => {
          setLoading(false);
        });
    };
    if (loading) {
      getPayload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, userId]);

  useEffect(() => {
    const getPayload = async () => {
      setAssessingPosture(true);
      const payload = JSON.stringify(await returnCurrentPosturePayload());
      getPostureResult(payload, userId)
        .then((r) => {
          setPostureResult(r.data);
        })
        .catch((err) => {
          createFlashbarMessage(
            `There was an issue assessing posture: ${
              err.message
            }. Unable to complete request to https://${ACCOUNT_MAPPINGS.get(
              window?.location.hostname
            )}`,
            "error"
          );
          setPostureResult({
            result: false,
            reason: {
              "os.type": "undetermined",
              "os.version": "undetermined",
              antivirus: "undetermined",
              firewall: "undetermined",
              structure: "undetermined",
            },
          });
        })
        .finally(() => {
          setAssessingPosture(false);
        });
    };
    if (assessingPosture) {
      getPayload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessingPosture, userId]);

  useEffect(() => {
    const createErrorText = (failureReason: string | undefined) => {
      if (failureReason === undefined) {
        return undefined;
      }
      return "- " + failureReason;
    };

    if (!loading && !assessingPosture) {
      let postureText;
      let structureText = postureResult?.reason.structure;
      if (postureResult?.result) {
        postureText = "Succeeded!";
      } else if (postureResult?.reason.structure === "undetermined") {
        postureText =
          "Failed! Unable to determine posture. See flashbar error message for more info.";
      } else {
        postureText = "Failed! Please fix the issue(s) below.";
      }

      setPostureHeader({
        result: postureResult?.result ? "success" : "fail",
        reason: createErrorText(postureText),
      });
      if (
        structureText !== undefined &&
        structureText.includes("Missing SiteConnect Posture Assessment Client")
      ) {
        // we want to use a better formatted text for missing Posture Assessment Client errors
        let configureText =
          "Please fix your posture assessment client. If not installed, follow instructions in the install page. If already installed, follow instructions in the updating page.";
        setStructure({
          result: chooseResult(postureResult?.reason.structure),
          reason: createErrorText(configureText),
        });
        setSupportedOS({
          result: chooseResult(postureResult?.reason["os.version"]),
          reason: createErrorText(configureText),
        });
        setOSType({
          result: chooseResult(postureResult?.reason["os.type"]),
          reason: createErrorText(configureText),
        });
        setFirewall({
          result: chooseResult(postureResult?.reason.firewall),
          reason: createErrorText(configureText),
        });
        setAntivirus({
          result: chooseResult(postureResult?.reason.antivirus),
          reason: createErrorText(configureText),
        });
        return;
      }
      setStructure({
        result: chooseResult(postureResult?.reason.structure),
        reason: createErrorText(structureText),
      });
      setSupportedOS({
        result: chooseResult(postureResult?.reason["os.version"]),
        reason: createErrorText(postureResult?.reason["os.version"]),
      });
      setOSType({
        result: chooseResult(postureResult?.reason["os.type"]),
        reason: createErrorText(postureResult?.reason["os.type"]),
      });
      setFirewall({
        result: chooseResult(postureResult?.reason.firewall),
        reason: createErrorText(postureResult?.reason.firewall),
      });
      setAntivirus({
        result: chooseResult(postureResult?.reason.antivirus),
        reason: createErrorText(postureResult?.reason.antivirus),
      });
    } else {
      setPostureHeader({ result: undefined, reason: undefined });
      setStructure({ result: undefined, reason: undefined });
      setSupportedOS({ result: undefined, reason: undefined });
      setOSType({ result: undefined, reason: undefined });
      setFirewall({ result: undefined, reason: undefined });
      setAntivirus({ result: undefined, reason: undefined });
    }
  }, [postureResult, loading, assessingPosture]);

  return (
    <Container fitHeight>
      <h2>SiteConnect Connection Issues</h2>
      <h3 className={postureHeader.result}>
        <SpaceBetween direction="horizontal" size="xs">
          <span>Your Posture Status</span>
          {loading || assessingPosture ? (
            <Spinner variant="disabled" size="big"></Spinner>
          ) : (
            postureHeader.reason
          )}
        </SpaceBetween>
      </h3>
      <p>
        <strong>
          Please note that all sections should be{" "}
          <span style={{ color: "green" }}> green</span>. This is required or
          your connection will be unsuccessful. You need to have the most recent
          version of the Posture Assessment Client installed. If you do not or
          are unsure, go back to{" "}
          <Link href="/windows/install.html">the install page</Link> and follow
          the install instructions.
        </strong>
      </p>
      {postureResult?.result === false && (
        <>
          <Alert type="info">
            If you need help debugging posture issues, you may enter your email
            before checking posture to help our team identify your posture. Note
            that this is an optional field and you are not required to enter
            your email to check posture.
          </Alert>
          <Input
            value={userId ?? ""}
            placeholder="Enter Email Address and click the button below"
            onChange={({ detail }) => {
              setUserId(detail.value);
            }}
            data-id="linkInputField"
          />
        </>
      )}
      <Button
        variant="primary"
        onClick={() => setAssessingPosture(true)}
        loading={assessingPosture}
      >
        Check Posture Again
      </Button>
      <ExpandableSection
        headerText={
          <span className={structure.result}>
            <strong>
              SiteConnect Posture Assessment Client Configured{" "}
              {structure.reason}
            </strong>
          </span>
        }
        defaultExpanded={structure.result !== "success"}
      >
        <p>
          If you have followed the{" "}
          <Link href="/windows/install.html">Windows Install</Link>{" "}
          instructions, then you should be able to open{" "}
          <Link
            external
            href="http://127.0.0.1:32182"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://127.0.0.1:32182
          </Link>{" "}
          and view the posture payload that is returned to SiteConnect. If the
          browser refuses to connect, then the Posture Assessment Client is{" "}
          <strong>not</strong> installed correctly.
        </p>
        <p>
          The easiest solution is to just re-run the install script in
          PowerShell as an Administrator which will correctly configure your
          system:
        </p>
        <CodeBlockWithCopy code={codeText} />
        <p>
          If this script fails then you must send the following log file to your
          Amazon representative for further troubleshooting:
          <br />
          <code>
            %USERPROFILE%\AppData\Local\SiteConnect\configure-siteconnect.log
          </code>
        </p>
      </ExpandableSection>
      <ExpandableSection
        headerText={
          <span className={OSType.result}>
            <strong>Valid Operating System {OSType.reason}</strong>
          </span>
        }
        defaultExpanded={OSType.result !== "success"}
      >
        <p>
          SiteConnect will only allow Windows editions to connect which
          currently recieve support and security updates.
        </p>
      </ExpandableSection>
      <ExpandableSection
        headerText={
          <span className={supportedOS.result}>
            <strong>
              Compliant Operating System version {supportedOS.reason}
            </strong>
          </span>
        }
        defaultExpanded={supportedOS.result !== "success"}
      >
        <p>Ensure your Operating System and version are supported:</p>
        <Table
          stripedRows
          variant="borderless"
          columnDefinitions={[
            { header: "Operating System", cell: (item) => item.os },
            { header: "Version", cell: (item) => item.version },
            { header: "Edition", cell: (item) => item.edition },
            { header: "Supported Until", cell: (item) => item.eos },
          ]}
          items={[
            {
              os: "Windows 10",
              version: "1809",
              edition: "Enterprise LTSC",
              eos: "January 9, 2029",
            },
            {
              os: "",
              version: "21H2",
              edition: "Enterprise",
              eos: "June 11, 2024",
            },
            {
              os: "",
              version: "",
              edition: "Enterprise LTSC",
              eos: "January 12, 2027",
            },
            {
              os: "",
              version: "22H2",
              edition: "Professional",
              eos: "October 14, 2025",
            },
            { os: "", version: "", edition: "Enterprise", eos: "" },
            {
              os: "Windows 11",
              version: "21H2",
              edition: "Enterprise",
              eos: "October 8, 2024",
            },
            { os: "", version: "22H2", edition: "Home", eos: "" },
            { os: "", version: "", edition: "Professional", eos: "" },
            {
              os: "",
              version: "",
              edition: "Enterprise",
              eos: "October 14, 2025",
            },
            {
              os: "",
              version: "23H2",
              edition: "Home",
              eos: "November 11, 2025",
            },
            { os: "", version: "", edition: "Professional", eos: "" },
            {
              os: "",
              version: "",
              edition: "Enterprise",
              eos: "November 10, 2026",
            },
          ]}
        />
      </ExpandableSection>
      <ExpandableSection
        headerText={
          <span className={firewall.result}>
            <strong>Firewall {firewall.reason}</strong>
          </span>
        }
        defaultExpanded={firewall.result !== "success"}
      >
        <p>
          SiteConnect will detect the included OS firewall and most other
          firewall products which Windows is able to detect.
        </p>
        <p>At least one firewall must be detected and enabled.</p>
      </ExpandableSection>
      <ExpandableSection
        headerText={
          <span className={antivirus.result}>
            <strong>Antivirus {antivirus.reason}</strong>
          </span>
        }
        defaultExpanded={antivirus.result !== "success"}
      >
        <p>
          SiteConnect will detect the included OS antivirus and most other
          antivirus products which Windows is able to detect.
        </p>
        <p>At least one antivirus must be detected and enabled.</p>
      </ExpandableSection>
      <h2>Authorization Issues</h2>
      <ul>
        <li id="user-not-authorized">
          <strong>User Not Authorized</strong>
          <p>
            If you&apos;ve validated that you have all necessary components
            installed:
          </p>
          <ol>
            <li>AWS VPN Client</li>
            <li>SiteConnect Posture Assessment Client</li>
          </ol>
          <p>
            and you are still rejected during a VPN connection attempt, then you
            are either using expired credentials, or an invalid OVPN file.
            Contact your Amazon representative for an updated pair of
            credentials and OVPN file.
          </p>
          <Alert type="warning">
            <strong>
              The credentials expire 30 minutes after creation, and last 8 hours
              after initial use.
            </strong>
          </Alert>
        </li>
      </ul>
      <h2>AWS VPN Client Connection Issues</h2>
      <p>
        Please ensure you are using the latest version of the AWS VPN Client.
        See{" "}
        <Link
          external
          href="https://docs.aws.amazon.com/vpn/latest/clientvpn-user/client-vpn-connect-windows.html#client-vpn-connect-windows-release-notes"
        >
          AWS VPN Client Release Notes
        </Link>
        .
      </p>
      <p>
        See the{" "}
        <Link
          external
          href="https://docs.aws.amazon.com/vpn/latest/clientvpn-user/windows-troubleshooting.html"
        >
          AWS VPN Client Windows Troubleshooting docs
        </Link>{" "}
        for specific problems related to the AWS VPN Client.
      </p>
      <h2>Providing Logs</h2>
      <p>
        To help assist with troubleshooting, you may be asked to provide the
        logs from your client connection.
        <br />
        Follow the steps below to find the correct log file to provide:
      </p>
      <h3>Windows</h3>
      <ol>
        <li>Run Powershell</li>
        <li>
          <p>
            Run the following code which returns the file path to the log file
            for your latest VPN connections:
          </p>
          <CodeBlockWithCopy code={powershellLogCode} />
        </li>
        <li>Use this file and provide it to your Amazon POC when asked.</li>
      </ol>
      <h2>Common Fixes</h2>
      <h3>Ensure the Tap Adapter is installed</h3>
      <p>
        If you get the <strong>Unknown error occured. Try again.</strong>{" "}
        message, it could be because the virtual network adapter is missing.
      </p>
      <Image src={Images.unknownErrorPng} alt="Unknown error occured" />
      <p>
        The AWS VPN Client <i>should</i> install the{" "}
        <strong>TAP-Windows Adapter V9</strong> during the installation process.
        You can verify this adapter is installed by looking at the network
        adapters on your device. This can be found either in the Control panel
        or the Device Manger.
      </p>
      <p>
        <strong>Control Panel:</strong>
      </p>
      <Image
        src={Images.controlPanelPng}
        alt="Control Panel > Network and Internet > Network Connections"
      />
      <p>
        <strong>Device Manager:</strong>
      </p>
      <Image src={Images.dvcMgrPng} alt="Device Manager > Network Adapters" />
      <p>
        If you do not see a TAP adapter installed, first try uninstalling the
        AWS VPN Client, rebooting your device, then re-installing the AWS VPN
        Client.
      </p>
      You can install a TAP adapter manually by following these steps:
      <ol>
        <li>Go to your Start Menu</li>
        <li>
          Type <i>tap-windows</i> in the search bar
        </li>
        <li>
          <p>
            Click on the <i>Add a new TAP-Windows6 virtual network adapter</i>{" "}
            option
          </p>
          <Image src={Images.installTapAdapterPng} alt="Install TAP Adapter" />
        </li>
      </ol>
      <p>
        This will install a TAP adapter. If you still do not see one installed
        on your system, or you need permissions, you will need to reach out to
        your IT Department.
      </p>
      <h3>Resetting TAP adapters</h3>
      <p>
        Some users have experienced problems with connecting to SiteConnect due
        to the AWS VPN Client failing to cleanup TAP adapters. Follow these
        steps to fix such a problem:
      </p>
      <ol>
        <li>Uninstall AWS VPN client</li>
        <li>In device manager, delete all TAP adapters manually</li>
        <li>Re-install AWS VPN client</li>
      </ol>
      <h3>Supported VPN Client Versions</h3>
      <p>
        Check the{" "}
        <Link
          external
          href="https://docs.aws.amazon.com/vpn/latest/clientvpn-user/client-vpn-connect-windows-release-notes.html"
          target="_blank"
        >
          Release Notes
        </Link>{" "}
        page for the minimum version you can use for the AWS VPN Client.
      </p>
      <h3>AWS VPN Client says the port is already in use</h3>
      <p>
        This is a known bug with the AWS VPN Client. Restarting the computer
        will fix this issue.
      </p>
    </Container>
  );
};
