import {
  AppLayout,
  Box,
  ContentLayout,
  Flashbar,
  Header,
} from "@amzn/awsui-components-react";
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { useFlashbarMessages } from "../common/utils";
import { HomePage } from "./home";
import { SideNav } from "./navigation";
import { PrerequisitesPage } from "./pre-requisites";
import { ConnectionPage } from "./windows/connecting";
import { FAQPage } from "./windows/faq";
import { InstallPage } from "./windows/install";
import { TroubleshootingPage } from "./windows/troubleshooting";
import { UpdatingPage } from "./windows/updating";

const App = () => {
  const [flashbarMessages, createFlashbarMessage] = useFlashbarMessages();
  const [pageTitle, setPageTitle] = React.useState<string>("");

  class Page {
    static readonly home = (<HomePage setPageTitle={setPageTitle} />);
    static readonly prereqs = (
      <PrerequisitesPage setPageTitle={setPageTitle} />
    );
    static readonly install = (<InstallPage setPageTitle={setPageTitle} />);
    static readonly connect = (<ConnectionPage setPageTitle={setPageTitle} />);
    static readonly update = (<UpdatingPage setPageTitle={setPageTitle} />);
    static readonly tshoot = (
      <TroubleshootingPage
        setPageTitle={setPageTitle}
        createFlashbarMessage={createFlashbarMessage}
      />
    );
    static readonly faq = (<FAQPage setPageTitle={setPageTitle} />);
  }

  const pageHeader = (
    <Header variant="awsui-h1-sticky">
      <Box variant="awsui-value-large">{pageTitle}</Box>
    </Header>
  );

  const router = (
    <Router>
      <Routes>
        <Route path="/" element={Page.home} />
        <Route
          path="/vpn-connection-prerequisites.html"
          element={Page.prereqs}
        />
        <Route path="/windows/install.html" element={Page.install} />
        <Route path="/windows/vpn-connect.html" element={Page.connect} />
        <Route path="/windows/updating.html" element={Page.update} />
        <Route path="/windows/troubleshooting.html" element={Page.tshoot} />
        <Route path="/windows/faq.html" element={Page.faq} />
      </Routes>
    </Router>
  );

  const content = (
    <ContentLayout
      defaultPadding
      header={pageHeader}
      headerVariant="high-contrast"
      maxContentWidth={1200}
    >
      {router}
    </ContentLayout>
  );

  return (
    <AppLayout
      disableContentPaddings
      toolsHide
      headerVariant="high-contrast"
      notifications={<Flashbar items={flashbarMessages} />}
      navigation={<SideNav />}
      navigationWidth={350}
      content={content}
    />
  );
};

export default App;
