import { SideNavigation } from "@amzn/awsui-components-react";

export const SideNav = () => (
  <SideNavigation
    activeHref="#"
    header={{
      href: "/",
      text: "Amazon SiteConnect VPN",
    }}
    items={[
      {
        type: "link",
        text: "Connection Prerequisites",
        href: "/vpn-connection-prerequisites.html",
      },
      {
        type: "section",
        text: "Windows",
        defaultExpanded: true,
        items: [
          {
            type: "link",
            text: "Install",
            href: "/windows/install.html",
          },
          {
            type: "link",
            text: "Connecting",
            href: "/windows/vpn-connect.html",
          },
          {
            type: "link",
            text: "Updating",
            href: "/windows/updating.html",
          },
          {
            type: "link",
            text: "Troubleshooting",
            href: "/windows/troubleshooting.html",
          },
          {
            type: "link",
            text: "FAQ",
            href: "/windows/faq.html",
          },
        ],
      },
    ]}
  />
);
