import { Container, Header, Link } from "@amzn/awsui-components-react";
import { Image } from "react-bootstrap";
import { BasePageProps } from "../../../common/pageProps";
import { Images } from "../../../images";

const connectionPageHeader = (
  <Header
    variant="h1"
    description={
      <>
        See the{" "}
        <Link href="/vpn-connection-prerequisites.html">
          VPN Connection Prerequisites
        </Link>{" "}
        before attempting to connect.
      </>
    }
  />
);

export const ConnectionPage = ({ setPageTitle }: BasePageProps) => {
  setPageTitle("Windows VPN Connection");
  return (
    <Container fitHeight header={connectionPageHeader}>
      <h2>Checking Posture Status</h2>
      <p>
        Before following the Connection Steps below,{" "}
        <strong>
          please verify that your posture meets the proper requirements. If your
          posture is not up to standard, your connection will be unsuccessful.
          View your posture status in the{" "}
          <Link href="/windows/troubleshooting.html">troubleshooting page</Link>
          .
        </strong>
      </p>

      <h2>Connection Steps</h2>
      <ol>
        <li>
          <p>
            Navigate to the provided URL from your Amazon contact. It will look
            something like{" "}
            <strong>
              https://self-service.clientvpn.amazonaws.com/xxxxxxxx
            </strong>
          </p>
        </li>
        <li>
          <p>
            You will be directed to authenticate with IdPrism if you have not
            recently authenticated.
            <br />
            Enter your IdPrism email address.
          </p>
          <Image
            src={Images.idPrismEnterEmailAddrPng}
            alt="Enter your email address in IdPrism"
          />
        </li>
        <li>
          <p>
            Enter your IdPrism password (this is the password you setup when you
            registered with IdPrism)
          </p>
          <Image
            src={Images.idPrismEnterPwPng}
            alt="Enter your password in IdPrism"
          />
        </li>
        <li>
          <p>
            Enter the verification code sent via SMS to the phone number in your
            IdPrism account
          </p>
          <Image
            src={Images.idPrismVerificationCodePng}
            alt="Enter verification code"
          />
        </li>
        <li>
          <p>
            You will then be redirected to the page to download the OPVN file
          </p>
          <Image src={Images.ovpnDlPagePng} alt="OVPN file download page" />
        </li>
        <li>
          <p>Open the AWS VPN Client</p>
          <Image
            src={Images.winAwsVpnClientOpenPng}
            alt="Open AWS VPN Client"
          />
        </li>
        <li>
          <p>
            Click <code>File &gt; Manage Profiles</code>
          </p>
          <Image
            src={Images.winAwsVpnClientCreateProfilePng}
            alt="Create Profile"
          />
        </li>
        <li>
          <p>
            Click <code>Add Profile</code>
          </p>
          <Image
            src={Images.winAwsVpnClientAddProfilePng}
            alt="Add The Profile"
          />
        </li>
        <li>
          <p>
            Set the <code>Display Name</code>, and provide the path to the OVPN
            file
          </p>
          <Image
            src={Images.winAwsVpnClientProfileDetailsPng}
            alt="Enter Profile Details"
          />
        </li>
        <li>
          <p>
            Click <code>Done</code>
          </p>
          <Image src={Images.winAwsVpnClientProfileAddedPng} alt="Click Done" />
        </li>
        <li>
          <p>
            Select the newly created profile and click <code>Connect</code>
          </p>
          <Image
            src={Images.winAwsVpnClientProfileSelectedPng}
            alt="Select Newly Created Profile"
          />
          <p>The AWS VPN Client will now open your default browser.</p>
        </li>
        <li>
          <p>
            If prompted, follow the IdPrism login flow,{" "}
            <strong>same steps as above.</strong>
          </p>
        </li>
        <li>
          <p>
            You will then be shown a table with all the networks you currently
            have active grants to. Ensure the site you are connecting to is in
            that table. <strong>Click the blue Connect button.</strong>
          </p>
          <p>
            <strong>NOTE:</strong> This table does not mean you have access to
            all of those sites and networks at the same time. It is just showing
            which sites you have active access to. You may only connect to one
            site at a time in the VPN client
          </p>
          <Image src={Images.userAccessTablePng} alt="Access Table" />
        </li>
        <li>
          <p>The connection should successfully establish</p>
          <Image
            src={Images.winAwsVpnClientLoginSuccess}
            alt="Login Successful"
          />
        </li>
      </ol>
      <p>You should now be connected to the VPN.</p>
      <p>
        For issues with connecting, see{" "}
        <Link href="/windows/troubleshooting.html">
          Windows Troubleshooting
        </Link>
        .
      </p>
    </Container>
  );
};
