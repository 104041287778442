import { Alert, Container, Link } from "@amzn/awsui-components-react";
import { BasePageProps } from "../../common/pageProps";

export const PrerequisitesPage = ({ setPageTitle }: BasePageProps) => {
  setPageTitle("Connection Prerequisites");
  return (
    <Container fitHeight>
      <Alert type="warning">
        If you are an <strong>Amazon employee</strong> use the internal policies
        and wikis to assist vendor setup.
      </Alert>
      <p>
        Before connecting to the VPN, third party vendors must have the
        following:
      </p>
      <ul>
        <li>
          Supported{" "}
          <Link href="/windows/troubleshooting.html#supported-os">
            OS version
          </Link>
        </li>
        <li>Enabled Firewall</li>
        <li>Enabled Antivirus</li>
        <li>AWS VPN Client</li>
        <li>
          SiteConnect Posture Assessment Client{" "}
          <Link href="/windows/install.html">Installed</Link>
        </li>
        <li>IdPrism account</li>
        <li>VPN Endpoint URL</li>
        <li>OVPN Configuration File</li>
      </ul>

      <h2>IdPrism Account</h2>
      <p>
        SiteConnect uses IdPrism as a central identity store for third-party
        vendors. All non-Amazon users must be registered in IdPrism before using
        SiteConnect. Your Amazon point of contact (POC) can onboard you into
        IdPrism.
      </p>
      <p>To get an IdPrism account:</p>
      <ul>
        <li>
          Your Amazon point of contact (POC) can onboard you into IdPrism.
        </li>
        <li>This is a one-time account setup.</li>
        <li>
          You will need an email address and phone number that can receive SMS.
        </li>
        <li>
          To check if you have an account already or to reset your password, you
          can log in and verify your account here:{" "}
          <Link
            external
            href="https://opstechitgate.cupid-na.idprism-auth.amazon.dev/"
            target="_blank"
          >
            https://opstechitgate.cupid-na.idprism-auth.amazon.dev/
          </Link>
        </li>
      </ul>

      <h2>VPN Endpoint URL</h2>
      <p>
        Once you have been granted access by your Amazon point of contact, they
        will provide you with a URL. This URL is where you will authenticate and
        download the OVPN configuration file (see below).
      </p>
      <ul>
        <li>
          You do not need this in order to{" "}
          <Link href="/windows/install.html">install SiteConnect</Link> or to{" "}
          <Link href="/windows/troubleshooting.html">check your posture.</Link>
        </li>
        <li>You will receive a new URL for every access request.</li>
        <li>
          For older vendors used to the token system, the URL replaces the
          token.
        </li>
      </ul>

      <h2>OVPN Configuration File</h2>
      <p>
        The OVPN configuration file has a <code>.ovpn</code>
        file extension and is also referred to as the <b>profile</b> when using
        the AWS VPN Client.
      </p>
      <ul>
        <li>
          You do not need this in order to{" "}
          <Link href="/windows/install.html">install SiteConnect</Link> or to{" "}
          <Link href="/windows/troubleshooting.html">check your posture.</Link>
        </li>
        <li>
          You will need a separate OVPN file for each site you attempt to
          connect to.
        </li>
        <li>
          The OVPN file becomes available after you authenticate at the provided
          URL from above.
        </li>
        <li>
          You will need to download a new OVPN file for every access request.
        </li>
      </ul>
    </Container>
  );
};
